<template>
  <div>
    <ValidationObserver
      v-slot="{ handleSubmit }"
      ref="observer"
      tag="div"
      class=" vld-parent"
    >
      <loading :active.sync="isLoading" :is-full-page="false" />
      <form @submit.prevent="handleSubmit(submitStepThree)" class="px-6 pt-6">
        <!-- start: parking & reservation -->
        <div class="flex items-center">
          <div class="w-1/2 pr-2">
            <text-input
              rules=""
              label="Earning Rate(Charging)"
              placeholder="e.g. $20"
              v-model="form.charging_rate"
            />
          </div>
          <div class="w-1/2 pl-2">
            <text-input
              rules=""
              label="Point Rate(Charging)"
              placeholder="e.g. 5"
              v-model="form.charging_point"
            />
          </div>
        </div>
        <!-- end: parking & reservation -->

        <!-- start: frequency -->
        <div class="flex items-center">
          <div class="w-1/2 pr-2">
            <text-input
              rules=""
              label="Earning Rate(Rebalance)"
              placeholder="e.g. $20"
              v-model="form.rebalance_rate"
            />
          </div>
          <div class="w-1/2 pl-2">
            <text-input
              rules=""
              label="Point Rate(Rebalance) "
              placeholder="e.g. 5"
              v-model="form.rebalance_point"
            />
          </div>
        </div>

        <button type="submit" ref="stepThreeSubmitButton" class="hidden">
          Save
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { FleetConfig } from '@/config/FleetConfig'
// import { DropdownConfig } from "@/config/DropdownConfig";
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import TextInput from '@/components/form/TextInput'

export default {
  name: 'AddEditFleetStep3',
  components: {
    TextInput,
  },
  data: function() {
    return {
      isLoading: false,
      form: {},
      supportMailConfig: false,
      // selected vehicle types
      vehicleTypes: [],
      visibility: undefined,
      // selected subscription plan
      subscription: undefined,
      subscriptionId: undefined,
      // all subscription plans detail
      subscriptionPlans: undefined,
      // getBillingPlanOptions: undefined
    }
  },
  computed: {
    ...mapGetters('fleet', ['isEditing', 'currentEditingFleetId']),
    ...mapState({
      // retrieve organization detail from step 1
      step1: (state) => state.fleet.step1,
      // retrieve subscription detail from step 2
      step2: (state) => state.fleet.step2,
      step3: (state) => state.fleet.step3,
    }),
  },
  async created() {
    if (this.isEditing) {
      this.form = this.step3
    }
  },
  methods: {
    reloadData() {
      console.log('update = ', this.step3)
    },
    submit: async function(btn_type, index, isNext, isPrevious) {
      // // todo: remove fake completion
      // EventBus.$emit(OrganizationConfig.events.succeededStep2, {
      //   subscription: "ab04ced6-5aea-4eb8-b9f2-b3e122b9f2c1"
      // });
      if (btn_type != 'normal' && this.isEditing) {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepThreeSubmitButton.click()
      }
    },
    submitStepThree: async function() {
      let method = 'PATCH'
      let charging_url = FleetConfig.api.single_charging(this.step1.id)
      let rebalance_url = FleetConfig.api.single_rebalance(this.step1.id)
      console.log('id = ', this.step1.id, charging_url, rebalance_url)

      let message = this.isEditing
        ? `Operator App updated successfully`
        : `Operator App added successfully`
      this.isLoading = true
      try {
        let url = ''
        let data = null

        var obj1 = {
          point: this.form.charging_point,
          rate: Number(this.form.charging_rate),
        }
        data = new xMan(obj1).toFormData()
        console.log('url = ', charging_url, method)

        url = charging_url
        let response = await this.$http({ method, url, data })
        console.log('first = ', response)

        url = rebalance_url
        var obj2 = {
          point: this.form.rebalance_point,
          rate: this.form.rebalance_rate,
        }
        data = new xMan(obj2).toFormData()

        let response2 = await this.$http({ method, url, data })
        console.log('second = ', response2)

        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )
        this.isLoading = false
        // Resolved
        var obj = {
          charging: response.data,
          //rebalance : response2.data,
        }
        EventBus.$emit(FleetConfig.events.succeededStep3, {
          res: obj,
        })
        return true
      } catch (ex) {
        this.isLoading = false
        console.log({ e2: ex })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
