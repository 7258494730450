<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal" class="vld-parent">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <google-map-modal
      :map_modal="map_modal"
      :location="loc"
      ref="google_map"
      v-on:closeGoogleModal="closeGoogleModal"
      v-on:saveGoogleModal="saveGoogleModal"
    >
    </google-map-modal>

    <template v-slot:header>
      <div class="panel-title">{{ titleText }}</div>
      <div class="w-full">
        <template v-if="isOperatorAppEnabled">
          <stepper
            :steps="stepStatusArr"
            v-model="currentStep"
            ref="stepper"
            :with-divider="false"
            v-on:input="changeTabs"
            v-on:errors="checkErrors"
            :edit="is_edit"
          >
            <template v-slot:step-1> Basic Info </template>
            <template v-slot:step-2> Configuration </template>
            <template v-slot:step-3> Operator App </template>
          </stepper>
        </template>
        <template v-else>
          <stepper
            :steps="stepStatusArr"
            v-model="currentStep"
            ref="stepper"
            :with-divider="false"
            v-on:input="changeTabs"
            v-on:errors="checkErrors"
            :edit="is_edit"
          >
            <template v-slot:step-1> Basic Info </template>
            <template v-slot:step-2> Configuration </template>
          </stepper>
        </template>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex items-center justify-end mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" class="ml-3" @click="submitStep(currentStep)">
          Save & Continue
        </t-button>
      </div>
    </template>

    <div v-if="errors.length > 0" class="pl-6 text-red-500">
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </div>

    <template v-if="currentStep === 1 && !isLoading">
      <add-edit-fleet-step-1
        ref="step1"
        v-on:showgooglemodal="showGoogleModal"
        v-on:isValid="isValidFunc"
      />
    </template>
    <template v-if="currentStep === 2 && !isLoading">
      <add-edit-fleet-step-2 ref="step2" v-on:isValid="isValidFunc" />
    </template>
    <template v-if="currentStep === 3 && isOperatorAppEnabled && !isLoading">
      <add-edit-fleet-step-3 ref="step3" v-on:isValid="isValidFunc" />
    </template>
  </slide-over-right>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import { EventBus } from '@/utils/EventBus'
import { FleetConfig } from '@/config/FleetConfig'

import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'

import Stepper from '@/components/stepper/Stepper'

import AddEditFleetStep1 from '@/views/fleet/AddEditFleetStep1'
import AddEditFleetStep2 from '@/views/fleet/AddEditFleetStep2'
import AddEditFleetStep3 from '@/views/fleet/AddEditFleetStep3'
import GoogleMapModal from '@/components/modals/GoogleMapModal.vue'

export default {
  name: 'AddEditFleet',
  components: {
    SlideOverRight,
    SlideOverRightTrigger,
    AnchorButton,
    Stepper,
    AddEditFleetStep1,
    AddEditFleetStep2,
    AddEditFleetStep3,
    GoogleMapModal,
  },
  data() {
    return {
      isLoading: false,
      // editing data
      item: {},
      // The organization id that will be edited
      // organizationId: undefined,
      errors: [],
      sorId: FleetConfig.events.sorId,
      // stepper
      stepStatusArr: [],
      map_modal: false,
      loc: {},
    }
  },
  props: {
    is_edit: {
      required: true,
    },
  },
  computed: {
    ...mapGetters('fleet', ['isEditing', 'currentEditingFleetId']),
    titleText() {
      return this.isEditing ? 'Edit Fleet' : 'Add Fleet'
    },
    currentStep: {
      get() {
        return this.$store.getters['fleet/currentStep']
      },
      set(updatedStep) {
        this.$store.dispatch('fleet/saveStep', {
          step: updatedStep,
        })
      },
    },
    isOperatorAppEnabled() {
      let subs = this.$store.getters['auth/organizationInfo'].subscription
      console.log('isOperatorA::', subs.operator_app_enabled)
      return subs.operator_app_enabled
    },
    ...mapState({
      step1data: (state) => state.fleet.step1,
      step2data: (state) => state.fleet.step2,
      step3data: (state) => state.fleet.step3,
    }),
  },
  async mounted() {
    // listeners
    EventBus.$on(FleetConfig.events.editingData, (item) => {
      this.$store.dispatch('fleet/saveLatLng', {
        data: {
          latitude: null,
          longitude: null,
        },
      })
      if (item.step == undefined) this.$store.dispatch('fleet/saveStep', 1)
      // remove old store
      this.$store.dispatch('fleet/forgetEditingStep')
      this.$store.dispatch('fleet/forgetAllStepsData')
      this.$store.dispatch('fleet/forgetEditingFleetId')
      // todo: forget last opened step?

      // pass data to step-* & trigger step-* from here
      this.item = item
      if (Object.keys(item).length > 1) {
        this.populateFleetStore(item.id)
        this.$store.dispatch('fleet/saveEditingStep', {
          step: 1,
        })
      } else {
        let limit = this.isOperatorAppEnabled ? 3 : 2
        var result = []
        for (var i = 0; i < limit; i++) {
          var obj = {
            disabled: true,
            visited: false,
            index: i,
            value: i + 1,
          }
          result.push(obj)
        }
        var vm = this
        setTimeout(() => {
          if (vm.$refs.stepper) vm.$refs.stepper.changeStepsArr(result)
        }, 500)
      }
      // console.log(this.isEditing);
      this.$nextTick(() => {
        let self = this
        console.log(self.$refs)
      })
    })

    EventBus.$on(FleetConfig.events.succeededStep1, (item) => {
      this.item = item
      // this.form.step1 = { ...this.item };
      this.$store.dispatch('fleet/saveEditingFleetId', {
        data: item.id,
      })
      this.$store.dispatch('fleet/saveStepData', {
        step: 1,
        data: item,
      })
      this.$store.dispatch('fleet/nextStep', {
        step: 2,
      })
      this.$refs.stepper.nextWithBtn()
    })

    EventBus.$on(FleetConfig.events.succeededStep2, (item) => {
      this.$store.dispatch('fleet/saveEditingFleetId', {
        data: item.id,
      })
      this.$store.dispatch('fleet/saveStepData', {
        step: 2,
        data: item,
      })

      if (this.isOperatorAppEnabled) {
        this.$store.dispatch('fleet/nextStep', {
          step: 3,
        })
        this.$refs.stepper.nextWithBtn()
      } else {
        this.closeSor()
      }
    })

    EventBus.$on(FleetConfig.events.succeededStep3, (item) => {
      this.$store.dispatch('fleet/saveStepData', {
        step: 3,
        data: item,
      })
      this.closeSor()
    })
  },
  methods: {
    closeSor() {
      this.$store.dispatch('fleet/forgetStep')
      this.$store.dispatch('fleet/forgetAllStepsData')
      // todo: close the sor & do other things
      dispatchEvent(new Event(FleetConfig.events.sorClose))
      // todo: Refetch the indexData
      dispatchEvent(new Event(FleetConfig.events.refresh))
    },
    saveGoogleModal() {
      this.$refs.step1.refreshLatLng()
      this.map_modal = false
    },
    closeGoogleModal() {
      this.map_modal = false
    },
    showGoogleModal() {
      this.map_modal = true
      this.$refs.google_map.initGoogleMap()
    },
    showModal() {
      dispatchEvent(new Event(FleetConfig.events.sorToggle))
    },
    submitStep: function(step) {
      this.$refs[`step${step}`].submit('normal')
    },

    async getStepDataFromApi(data, step) {
      var index = step - 1
      var result = {}
      switch (step) {
        case 1:
          result = {
            name: data.name,
            organization: data.organization,
            warehouse_name: data.warehouse_name,
            warehouse_lat: data.warehouse_lat,
            warehouse_lon: data.warehouse_lon,
            use_company_details: data.use_company_details,
            country: data.country.id ? data.country.id : '',
            city: data.city ? data.city.id : '',
            zip_code: data.zip_code,
            address: data.address,
            registration_no:
              data.registration_no == 'null' ? '' : data.registration_no,
            tax_id: data.tax_id == 'null' ? '' : data.tax_id,
            id: data.id,
          }

          if (result.name != '') {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: true,
              disabled: false,
            }
          } else {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: false,
              disabled: true,
            }
          }
          break
        case 2:
          result = {
            is_parking_required: data.is_parking_required,
            reservation_time: data.reservation_time,
            on_ride_location_update_frequency:
              data.on_ride_location_update_frequency,
            off_ride_location_update_frequency:
              data.off_ride_location_update_frequency,
            max_speed_limit: data.max_speed_limit,
            visibility: data.visibility,
            private_code: data.private_code,
            private_code_refresh_time: data.private_code_refresh_time,
            email_enabled: data.email_enabled,
            email_address: data.email_address,
            email_port: data.email_port,
            email_host: data.email_host,
            email_encryption: data.email_encryption,
          }
          if (
            result.is_parking_required == false ||
            result.is_parking_required == true
          ) {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: true,
              disabled: false,
            }
          } else {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: false,
              disabled: true,
            }
          }
          break
        case 3:
          if (this.isOperatorAppEnabled) {
            var cdata = await this.getChargingData(data.id)
            var rdata = await this.getRebalanceData(data.id)
            if (cdata == undefined && rdata == undefined) {
              result = {
                charging_rate: null,
                charging_point: null,
                rebalance_rate: null,
                rebalance_point: null,
              }
            } else {
              result = {
                charging_rate: cdata && cdata.rate ? cdata.rate : null,
                charging_point: cdata && cdata.point ? cdata.point : null,
                rebalance_rate: rdata && rdata.rate ? rdata.rate : null,
                rebalance_point: rdata && rdata.point ? rdata.point : null,
              }
            }
            if (cdata == undefined && rdata == undefined) {
              this.stepStatusArr[index] = {
                index: index,
                value: index + 1,
                visited: false,
                disabled: true,
              }
            } else {
              this.stepStatusArr[index] = {
                index: index,
                value: index + 1,
                visited: true,
                disabled: false,
              }
            }
          }

          break
        default:
          break
      }
      return result
    },
    async getChargingData(id) {
      let cdata = {
        rate: '',
        point: '',
      }
      await this.$http
        .get(FleetConfig.api.single_charging(id))
        .then((res) => {
          console.log('cc', res.data)
          cdata = res.data
        })
        .catch((err) => console.log(err))
      return cdata
    },
    async getRebalanceData(id) {
      let rdata = {
        rate: '',
        point: '',
      }
      await this.$http
        .get(FleetConfig.api.single_charging(id))
        .then((res) => {
          console.log('rr', res.data)
          rdata = res.data
        })
        .catch((err) => console.log(err))
      return rdata
    },
    async populateFleetStore(id) {
      // todo: clear store if id is undefined/empty
      this.isLoading = true
      await this.$http
        .get(FleetConfig.api.single(id))
        .then((res) => {
          let org = res.data
          this.saveStepData(org)
        })
        .catch((err) => {
          console.log('popu-err', err)
        })
    },
    async saveStepData(org) {
      var step1_data = await this.getStepDataFromApi(org, 1)
      var step2_data = await this.getStepDataFromApi(org, 2)

      setTimeout(() => {
        if (this.$refs.stepper)
          this.$refs.stepper.changeStepsArr(this.stepStatusArr)
      }, 500)

      if (this.item.step == undefined) {
        this.$store.dispatch('fleet/saveStep', {
          step: 1,
        })
      } else {
        this.$store.dispatch('fleet/saveStep', {
          step: this.item.step == null ? 1 : this.item.step,
        })
      }
      this.$store.dispatch('fleet/saveEditingFleetId', {
        data: org.id,
      })

      this.$store.dispatch('fleet/saveStepData', {
        step: 1,
        data: step1_data,
      })

      this.$store.dispatch('fleet/saveStepData', {
        step: 2,
        data: step2_data,
      })

      if (this.isOperatorAppEnabled) {
        var step3_data = await this.getStepDataFromApi(org, 3)
        this.$store.dispatch('fleet/saveStepData', {
          step: 3,
          data: step3_data,
        })
      }

      this.isLoading = false
    },

    checkErrors(oldIdx, index, isNext, isPrevious) {
      if (isPrevious && !isNext) {
        this.$refs.stepper.enoughMove(index)
      } else {
        this.$refs[`step${oldIdx + 1}`].submit(
          'tabselect',
          index,
          isNext,
          isPrevious
        )
      }
    },
    changeTabs(index) {
      var vm = this
      setTimeout(() => {
        switch (index) {
          case 1:
            if (vm.$refs.step1) vm.$refs.step1.reloadData()
            break
          case 2:
            if (vm.$refs.step2) vm.$refs.step2.reloadData()
            break
          case 3:
            if (vm.$refs.step3) vm.$refs.step3.reloadData()
            break
        }
      }, 1000)
    },
    isValidFunc(flag, index, isNext, isPrevious) {
      //only move
      this.isValid = flag
      if (this.isValid || isPrevious) {
        this.$refs.stepper.enoughMove(index)
        this.isValid = false
      }
    },
    // getSteps (steps, index) {
    //   this.stepStatusArr = steps
    //   console.log('now = ' , index)
    // },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
