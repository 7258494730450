<template>
  <v-easy-dialog v-model="map_modal" persistent width="700">
    <div class="modal-body">
      <!-- <div class="col-span-3">Check out our stacked Dialog</div>

            <div class="col-span-4">
                Notice that tab / shift+tab will only stay within this dialog.
            </div>

            <div class="flex justify-end space-x-2">
                <button @click="simpleDialog = false">Close</button>
            </div> -->
      <div class="modal-header flex">
        <div class="flex items-center w-2/3">
          <span class="drag">{{ mtitle }}</span>
        </div>
        <div class="block items-center justify-end  w-1/3 text-left mt-1">
          <!-- <span class="lat-txt block">Place : {{place != null ? '' : place}}</span> -->
          <span v-if="marker != null" class="lat-txt"
            >Lat : {{ marker.lat }}</span
          >
          <div class="flex items-center">
            <span v-if="marker != null" class="lat-txt"
              >Lng : {{ marker.lng }}</span
            >
          </div>
        </div>
      </div>
      <div class="modal-main">
        <gmap-map
          ref="map"
          :zoom="zoom"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100%;"
          @click="setMarker"
        >
          <gmap-marker
            :position="marker"
            v-if="marker != null"
            :draggable="true"
            @drag="setMarker"
          />
          <!-- <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true" @click="center=m.position" /> -->
        </gmap-map>
      </div>
      <div class="modal-footer">
        <div class="okbutton items-cetner flex" @click="okClicked">
          <span>{{ mokButton }}</span>
        </div>
        <div class="cancelbutton items-center flex" @click="cancelClicked">
          <span>{{ mcancelButton }}</span>
        </div>
      </div>
    </div>
  </v-easy-dialog>
  <!-- <t-modal v-model="showModal" name="my-modal">hello world</t-modal> -->
</template>

<script>
import VEasyDialog from 'v-easy-dialog'
import { mapState } from 'vuex'

export default {
  name: 'GoogleMapModal',
  components: {
    VEasyDialog,
  },
  props: {
    map_modal: {
      required: true,
      type: Boolean,
    },
    location: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      mtitle: 'Drag PIN to locate exact location',
      mdescription: 'Your Current editing will be lost if you exit.',
      mokButton: 'Save',
      mcancelButton: 'Cancel',
      zoom: 12,
      center: {
        lat: 10,
        lng: 10,
      },
      marker: null,
      place: null,
    }
  },
  computed: {
    ...mapState({
      placeId: (state) => state.fleet.place_id,
      latitude: (state) => state.fleet.latitude,
      longitude: (state) => state.fleet.longitude,
      // currentStep: state => state.organization.step
    }),
  },
  methods: {
    initGoogleMap() {
      console.log('this. = ', this.latitude)
      if (this.latitude != null && this.longitude != null) {
        this.center = {
          lat: Number(this.latitude),
          lng: Number(this.longitude),
        }
        this.marker = {
          lat: Number(this.latitude),
          lng: Number(this.longitude),
        }
      } else {
        this.marker = null
      }
    },

    cancelClicked() {
      this.$emit('closeGoogleModal')
    },
    okClicked() {
      this.$emit('saveGoogleModal', this.marker, this.place)
    },
    setMarker(info) {
      console.log('info = ', info)
      this.marker = {
        lat: info.latLng.lat(),
        lng: info.latLng.lng(),
      }
      this.$store.dispatch('fleet/saveLatLng', {
        data: {
          latitude: info.latLng.lat(),
          longitude: info.latLng.lng(),
        },
      })
    },
  },
}
</script>
<style>
.v-easy-dialog--content-container {
  width: 700px;
  border-radius: 10px;
  height: 500px;
  background: white;
  z-index: 1105;
  box-shadow: 0 2px 12px 0 rgba(109, 134, 149, 0.16);
}
.v-easy-dialog--backdrop {
  z-index: 1300 !important;
}
.modal-header {
  text-align: center;
  height: 15%;
  padding: 13px;
}
.drag {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.modal-main {
  text-align: center;
  display: grid;
  height: 75%;
}
.modal-main i {
  font-size: 32px;
  margin-bottom: 10px;
  color: #ff8306;
  margin-top: 10px;
}
.modal-main span {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #323740;
  padding: 0 15px;
  word-break: break-all;
}
.modal-footer {
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 15px;
}
.okbutton {
  width: 100px;
  height: 40px;
  background: #04bc34;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 15px;
  display: flex;
}
.okbutton:hover {
  opacity: 0.7;
}
.okbutton span {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: auto;
}
.cancelbutton {
  width: 100px;
  height: 40px;
  background: #f0f1f1;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
}
.cancelbutton span {
  margin: auto;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #505659;
}
.cancelbutton:hover {
  opacity: 0.7;
}
.lat-txt {
  font-size: 13px;
  font-weight: normal;
  padding: 2px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  white-space: nowrap;
}
</style>
