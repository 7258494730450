export const OrganizationConfig = {
  api: {
    // get
    index: '/dashboard/organizations/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    // post [add-step-1]
    create: '/dashboard/organizations/',
    // patch [edit|add-step-2]
    update: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    summary: {},
    profile: {},
    fleets: {
      // todo: merge?
      // get
      index: '/dashboard/fleets/',
      create: '',
    },
    vehicleDomain: {
      // get
      index: (orgId = 'uuid') => {
        return `/dashboard/organizations/${orgId}/vehicle-type-settings/`
      },
      // post
      create: (orgId = 'uuid') => {
        return `/dashboard/organizations/${orgId}/vehicle-type-settings/`
      },
      // Patch
      update: (configId = 'uuid') => {
        return `/dashboard/organizations/vehicle-type-settings/${configId}/`
      },
    },
  },
  events: {
    name: 'organization',
    // refresh
    refresh: `refresh-organization`,
    // slideover-right
    sorId: 'organization',
    sorOpen: 'sor-open-organization',
    sorClose: 'sor-close-organization',
    sorToggle: 'sor-toggle-organization',
    // editing-data
    editingData: 'edit-organization-data',
    editingStep1Data: 'edit-organization-step-1-data',
    editingStep2Data: 'edit-organization-step-2-data',
    editingStep3Data: 'edit-organization-step-3-data',
    editingStep4Data: 'edit-organization-step-4-data',

    succeededStep1: 'succeeded-step-1',
    succeededStep2: 'succeeded-step-2',
    succeededStep3: 'succeeded-step-3',
    succeededStep4: 'succeeded-step-4',

    // viewing-data
    viewingData: 'view-organization-data',
  },
}
