<template>
  <div class="w-full">
    <input-label :text="label" />

    <gmap-autocomplete
      class="block w-full px-4 py-2 leading-normal bg-white border border-gray-400 rounded outline-none h-35px focus:outline-none focus:border-gray-700 focus:placeholder-gray-500"
      @place_changed="handleChangingPlace"
    >
      <template v-slot:input="slotProps">
        <v-text-field
          outlined
          prepend-inner-icon="place"
          placeholder="Location Of Event"
          ref="input"
          v-on:listeners="slotProps.listeners"
          v-on:attrs="slotProps.attrs"
        >
        </v-text-field>
      </template>
    </gmap-autocomplete>
  </div>
</template>

<script>
import InputLabel from './InputLabel.vue'

export default {
  name: 'PlacesAutocomplete',
  components: {
    InputLabel,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: 'Fleet wirehouse location',
    },
  },
  methods: {
    handleChangingPlace(e) {
      // console.log("changed:", e.geometry);
      this.$emit('place-changed', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.pac-container {
  z-index: 999999;
  /* hide powered by google */
  &:after {
    background-image: none !important;
    height: 0px;
  }
}
</style>
