export const BasicSettingConfig = {
  api: {
    // get
    index: function(id = 'uuid') {
      return `/dashboard/organizations/${id}/basic-settings/`
    },
    // patch
    update: function(id = 'uuid') {
      return `/dashboard/organizations/${id}/basic-settings/`
    },
  },
}

export const VehicleSettingsConfig = {
  api: {
    // get
    index: function(id = 'uuid') {
      return `/dashboard/organizations/${id}/vehicle-settings/`
    },
    // patch
    update: function(id = 'uuid') {
      return `/dashboard/organizations/${id}/vehicle-settings/`
    },
  },
}

export const EventSettingsConfig = {
  api: {
    // get
    index: function(id = 'uuid') {
      return `/dashboard/organizations/${id}/event-settings/`
    },
    // patch
    update: function(id = 'uuid') {
      return `/dashboard/event-settings/${id}/`
    },
  },
}

export const GeneralSettingsConfig = {
  api: {
    // get
    index: function(id = 'uuid') {
      return `/dashboard/organizations/${id}/general-settings/`
    },
    // patch
    update: function(id = 'uuid') {
      return `/dashboard/organizations/${id}/general-settings/`
    },
  },
}

export const OrganizationPointSettingsConfig = {
  api: {
    // get
    index: `/dashboard/organization-behavior-points/`,
    // get
    single: function(id = 'uuid') {
      return `/dashboard/organization-behavior-points/${id}/`
    },
    // patch
    update: function(id = 'uuid') {
      return `/dashboard/organization-behavior-points/${id}/`
    },
    rewardPointGroupIndex: `/dashboard/reward-points/`,
    rewardPointGroupUpdate: `/dashboard/reward-points/bulk-update/`,
  },
  events: {
    behaviourPopup: 'behaviour-points-popup',
  },
}

export const PaymentSettingsConfig = {
  api: {
    gateway: {
      // get
      index: `/dashboard/payment-gateways/`,
      // patch
      update: function(id = 'uuid') {
        return `/dashboard/payment-gateway-configs/${id}/`
      },
    },
    topup: {
      // get
      index(id = 'uuid') {
        return `dashboard/organizations/${id}/topup-settings/`
      },
      // patch
      update(id = 'uuid') {
        return `dashboard/organizations/${id}/topup-settings/`
      },
    },
  },
}

export const OperatorSettingsConfig = {
  api: {
    general: {
      // get
      index: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/operator-general-settings/`
      },
    },
    charging: {
      // get
      index: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/operator-task-charging-settings/`
      },
      // patch
      update: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/operator-task-charging-settings/`
      },
    },
    rebalancing: {
      // get
      index: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/operator-task-rebalancing-settings/`
      },
      // patch
      update: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/operator-task-rebalancing-settings/`
      },
    },
  },
}

export const BusinessHourSettingsConfig = {
  api: {
    // get
    index: function(id = 'uuid') {
      return `/dashboard/organizations/${id}/business-hour-settings/`
    },
    // patch
    update: function(id = 'uuid') {
      return `/dashboard/business-hour-settings/${id}/`
    },
  },
}
