<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    ref="observer"
    class="vld-parent"
    tag="div"
  >
    <loading :active.sync="isLoading" :is-full-page="false" />
    <form
      @submit.prevent="handleSubmit(submitStepOne)"
      enctype="multipart/form-data"
      class="px-6"
    >
      <!-- start: name & phone -->
      <div class="flex items-center">
        <!-- <div class="w-1/2 pr-2"> -->
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Fleet Name"
            placeholder="Fleet name"
            v-model="form.name"
          />
        </div>
        <div class="w-1/2 pl-2">
          <input-label error="" text="Organization" />
          <t-rich-select
            :options="organizations"
            valueAttribute="id"
            textAttribute="name"
            placeholder="Select"
            v-model="form.organization"
          />
          <input-error-item message="" />
          <!-- <text-input
            rules=""
            label="Organization"
            placeholder="eScoot"
            v-model="form.organization"
            :disabled="true"
          /> -->
        </div>
      </div>
      <!-- end: name & phone -->

      <!-- start: geofence & warehouse -->
      <div class="relative flex items-center mb-6">
        <div class="w-11/12">
          <places-autocomplete @place-changed="handleChangingPlace" />
        </div>
        <div class="flex items-center justify-center w-1/12 google-map">
          <div class="mt-6 mr-0 map-btn" @click="showGooglemapModal">
            <i class="fas fa-map"></i>
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <text-input
          rules="required"
          label="Ware House Name"
          placeholder="Ware House Name"
          v-model="form.warehouse_name"
          ref="whs_input"
          :disabled="true"
        />
      </div>

      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Longitude"
            placeholder="Longitude"
            v-model="form.warehouse_lon"
            :disabled="true"
            ref="lng_input"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="Latitude"
            placeholder="Latitude"
            v-model="form.warehouse_lat"
            :disabled="true"
            ref="lat_input"
          />
        </div>
      </div>
      <!-- end: lon & lat -->
      <label class="flex items-center w-full mt-8 mb-4 cursor-pointer">
        <input
          type="checkbox"
          class="form-checkbox focus:shadow-none text-oBlack focus:border-oDark border-oBlack"
          v-model="form.end_ride_picture"
        />
        <span class="ml-3 font-semibold select-none text-14px text-oBlack">
          End Ride Photo Required
        </span>
      </label>

      <label class="flex items-center w-full mt-8 mb-4 cursor-pointer">
        <input
          type="checkbox"
          class="form-checkbox focus:shadow-none text-oBlack focus:border-oDark border-oBlack"
          v-model="form.use_company_details"
        />
        <span class="ml-3 font-semibold select-none text-14px text-oBlack">
          Use Company Details
        </span>
      </label>

      <!-- start: address & city -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <ValidationProvider
            name="Country"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Country *" />
            <t-rich-select
              :options="countries"
              valueAttribute="id"
              textAttribute="name"
              placeholder="Select a country"
              :variant="{
                danger: !!errors[0],
                readonly: getCompanyDetailsDisabledState,
              }"
              v-model="form.country"
              :disabled="getCompanyDetailsDisabledState"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
        <div class="w-1/2 pl-2">
          <ValidationProvider name="City" v-slot="{ errors }">
            <input-label :error="errors[0]" text="City" />
            <t-rich-select
              :options.sync="cities"
              valueAttribute="id"
              textAttribute="name"
              :placeholder.sync="getCityPlaceholder"
              :disabled="getCityDisabledState"
              :variant="{
                danger: !!errors[0],
                readonly: getCompanyDetailsDisabledState,
              }"
              v-model="form.city"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div>
      <!-- end: geofence & warehouse -->

      <!-- start: zip & tax -->
      <div class="flex items-center">
        <!-- <div class="w-1/2 pr-2"> -->
        <div class="w-full">
          <text-input
            rules=""
            label="Zip Code"
            placeholder="1234"
            v-model="form.zip_code"
            :disabled="getCompanyDetailsDisabledState"
          />
        </div>
      </div>
      <!-- end: zip & tax -->
      <div>
        <ValidationProvider name="Address" rules="" v-slot="{ errors }">
          <input-label :error="errors[0]" text="Address" />
          <t-textarea
            rules=""
            v-model="form.address"
            :disabled="getCompanyDetailsDisabledState"
            :variant="{
              readonly: getCompanyDetailsDisabledState,
            }"
          ></t-textarea>
          <input-error-item :message="errors[0]" />
        </ValidationProvider>
      </div>

      <!-- start: registration-no -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules=""
            label="Registration ID"
            placeholder="e.g. 123 446 7890"
            v-model="form.registration_no"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules=""
            label="Tax ID"
            placeholder="e.g. TX123 456 7890"
            v-model="form.tax_id"
          />
        </div>
      </div>

      <!-- <gmap-map
        ref="map"
        :zoom="zoom"
        :center="center"
        map-type-id="terrain"
        style="width: 100%; height: 100%;"
        class="hidden"
      /> -->
      <!-- end: registration-no -->
      <button type="submit" ref="stepOneSubmitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { FleetConfig } from '@/config/FleetConfig'
import { DropdownConfig } from '@/config/DropdownConfig'
import { OrganizationConfig } from '@/config/OrganizationConfig'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import { gmapApi } from 'vue2-google-maps'
import PlacesAutocomplete from '@/components/form/PlacesAutocomplete.vue'
// import { GooglePlacesAutocomplete } from "vue-better-google-places-autocomplete";

export default {
  name: 'AddEditFleetStep1',
  components: {
    InputLabel,
    TextInput,
    PlacesAutocomplete,
    // GooglePlacesAutocomplete
  },
  data: function() {
    return {
      isLoading: false,
      item: {},
      form: {},
      useCompanyDetails: false,
      service_areas: [], //made by guang
      // country & city
      countries: undefined,
      cities: undefined,
      cityIsLoading: false,
      place_id: '',
      house_name: '',
      organizations: [],
      zoom: 12,
      center: {
        lat: 10,
        lng: 10,
      },
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters('fleet', ['isEditing', 'currentEditingFleetId']),
    step1: function() {
      return this.$store.state.fleet.step1
    },
    getCountry: function() {
      return this.form.country || undefined
    },
    getOrganization: function() {
      return this.form.organization || undefined
    },
    getCityDisabledState: function() {
      if (!this.form.country) return true
      if (this.cityIsLoading) return true
      if (this.getCompanyDetailsDisabledState) return false
      return false
    },
    getCityPlaceholder: function() {
      if (!this.form.country) return 'Select a country first'
      if (this.cityIsLoading) return 'Loading ⟳'
      return 'Select city'
    },
    getUseCompanyDetails: function() {
      return this.form.use_company_details
    },
    getCompanyDetailsDisabledState: function() {
      return !!this.getUseCompanyDetails
    },
    ...mapState({
      step1data: (state) => state.fleet.step1,
      placeId: (state) => state.fleet.place_id,
      latitude: (state) => state.fleet.latitude,
      longitude: (state) => state.fleet.longitude,
      // currentStep: state => state.organization.step
    }),
  },
  async created() {
    // todo: remove logs, push to errors array
    var orgs = await this.$http
      .get(OrganizationConfig.api.index)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    var init_arr = {
      id: '',
      name: '--',
    }
    this.organizations.push(init_arr)
    for (var i = 0; i < orgs.length; i++) {
      this.organizations.push(orgs[i])
    }

    this.countries = await this.$http
      .get(DropdownConfig.api.country)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))

    this.service_areas = await this.$http
      .get(DropdownConfig.api.serviceAreaFleets)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    console.log('res = ', this.isEditing)
    if (this.isEditing) {
      this.form = this.step1
      this.house_name = this.step1.warehouse_name
    }
  },
  watch: {
    getCountry: async function(updatedCountry) {
      this.form.city = this.isEditing ? this.form.city : this.form.city
      this.cityIsLoading = true
      this.cities = await this.$http
        .get(DropdownConfig.api.city(updatedCountry))
        .then((res) => res.data.data)
        .catch((err) => console.log(err))
        .finally(() => (this.cityIsLoading = false))
    },
    getOrganization: async function(id) {
      if (id == '' || id == undefined) {
        this.form.country = undefined
        this.form.city = undefined
        this.form.address = ''
        this.form.zip_code = ''
        this.form.use_company_details = false
      } else {
        // this.form.use_company_details = true;
        this.$http
          .get(OrganizationConfig.api.single(id))
          .then((res) => {
            var data = res.data
            if (this.form.use_company_details) {
              this.form.city = data.city.id
              this.form.country = data.country.id
              this.form.address = data.address
            } else {
              this.backup = data
            }
          })
          .catch((err) => {
            console.log('err - ', err)
          })
      }
    },
    currentEditingFleetId: function(updatedId) {
      console.log(this.currentEditingFleetId)
      console.log('wae1', this.step1)
      if (updatedId !== undefined) {
        this.form = {
          ...this.step1,
        }
        return
      }
      this.form = {}
      return
    },
    getUseCompanyDetails: function(updatedState) {
      if (updatedState === true) {
        if (this.backup) {
          this.form.city = this.backup.city.id
          this.form.country = this.backup.country.id
          this.form.address = this.backup.address
          this.form.zip_code = this.backup.zip_code
        } else {
          this.form.city = 3
          this.form.country = 19
          this.form.zip_code = '1231'
          this.form.address = 'the_scoot@gmail.com'
        }
      } else {
        this.form.country = undefined
        this.form.city = undefined
        this.form.address = ''
        this.form.zip_code = ''
      }
    },
  },
  methods: {
    gauto(ev) {
      console.log('gmUA:', ev)
    },
    async getAddressData(data) {
      console.log('data = ', data)
      var lat = data.geometry.location.lat().toString()
      var lng = data.geometry.location.lng().toString()
      this.getWareHouseName(lat, lng)
      this.$store.dispatch('fleet/saveLatLng', {
        data: {
          latitude: data.geometry.location.lat().toString(),
          longitude: data.geometry.location.lng().toString(),
        },
      })
      this.changeValues()
    },
    reloadData() {
      this.form = this.step1
    },
    showGooglemapModal() {
      this.$emit('showgooglemodal')
    },
    handleChangingPlace(e) {
      console.log('sfsdsfsfsa', e)
      this.$refs.whs_input.innerValue = e.formatted_address
      this.$refs.lat_input.innerValue = e.geometry.location.lat()
      this.$refs.lng_input.innerValue = e.geometry.location.lng()
    },
    getWareHouseName(lat, lng) {
      var geocoder = new this.google.maps.Geocoder()
      var latlng = { lat: lat, lng: lng }
      var vm = this
      geocoder.geocode({ location: latlng }, function(results, status) {
        if (status === 'OK') {
          if (results[1]) {
            vm.form.warehouse_name = results[1].formatted_address
            vm.$refs.lat_input.innerValue = lat
            vm.$refs.lng_input.innerValue = lng
          } else {
            window.alert('No results found')
            vm.$refs.lat_input.innerValue = lat
            vm.$refs.lng_input.innerValue = lng
          }
        }
      })
    },
    refreshLatLng() {
      this.getWareHouseName(this.latitude, this.longitude)
    },
    changeValues() {},
    submit: async function(btn_type, index, isNext, isPrevious) {
      if (btn_type != 'normal') {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepOneSubmitButton.click()
      }
    },
    submitStepOne: async function() {
      this.isLoading = true
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? FleetConfig.api.update(this.currentEditingFleetId)
        : FleetConfig.api.create
      if (this.form.use_company_details) {
        this.form.city = '3'
      }
      delete this.form.service_area

      this.form.off_ride_location_update_frequency = 5
      this.form.on_ride_location_update_frequency = 1

      this.form.max_speed_limit = 'LOW'
      this.form.reservation_time = '1'

      let data = new xMan(this.form).toFormData()

      let message = this.isEditing
        ? 'Fleet Basic Info updated successfully'
        : 'Fleet Basic Info added successfully'

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // todo: remove logs
        console.log({ res: response.data })
        // Reset the form
        //this.form = {};
        // Notify
        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )
        // Resolved
        console.log('response = ', response.data)
        EventBus.$emit(FleetConfig.events.succeededStep1, response.data)
        return true
      } catch (e) {
        // todo: remove logs
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        // console.log({ sdhf: typeof error });
        // console.log({ e1: Object.keys(error) });
        console.log({ e1: e.response.data })
        //console.log()
        var errorData = Object.values(e.response.data)
        if (errorData.length > 0) {
          this.$emit('show_error', errorData[0][0])
        }
      }
    },
  },
}
</script>
<style>
.vbga-results {
  position: absolute;
  background: white;
  z-index: 2000;
  box-shadow: 0px 2px 6px 0px #d8d7d7;
  padding: 10px;
  /* width: 300px; */
  min-width: 240px;
  max-width: 300px;
}
.vbga-results li {
  height: 30px;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  white-space: nowrap;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vbga-results li:hover {
  opacity: 0.8 !important;
}
.vbga-input {
  width: 100% !important;
  border: none;
  border: 1px solid #cbd5e0;
  height: 36px !important;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 38px;
}
.vbga-input:focus {
  outline: none;
}
.border-error {
  border: 1px solid #e53e3e;
}
.google-map {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0px;
}
.map-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #ff598a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-btn:hover {
  opacity: 0.8;
}
.map-btn i {
  color: white;
  font-size: 12px;
}
.pac-container.pac-logo {
  z-index: 999999;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}
</style>
