<template>
  <div class="relative z-20 mr-2">
    <div
      :class="
        `relative object-cover overflow-hidden bg-gray-300 border-0 rounded-full`
      "
      :style="getStyle"
    >
      <img
        v-if="avatar"
        :src="avatar"
        :class="`object-cover rounded-full`"
        :style="getStyle"
      />
    </div>
    <div v-if="badge" class="avatar-badge" :class="badgeVariant">
      {{ badge }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'DisplayAvatar',
  props: {
    avatar: {
      type: String,
      required: false,
    },
    width: {
      type: [String, Number],
      required: false,
      default: '6rem',
    },
    height: {
      type: [String, Number],
      required: false,
      default: '6rem',
    },
    badge: {
      type: String,
      required: false,
    },
    badgeVariant: {
      type: String,
      required: false,
      default: 'fallback',
      validator(val) {
        const whitelist = ['fallback', 'green', 'orange', 'red']
        return whitelist.indexOf(val) !== -1
      },
    },
  },
  computed: {
    getStyle() {
      let width = Number.isInteger(this.width) ? `${this.width}px` : this.width
      let height = Number.isInteger(this.height)
        ? `${this.height}px`
        : this.height

      // console.log(width, height)
      return `width: ${width}; height: ${height}`
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-badge {
  width: 6rem;
  @apply relative z-40  flex items-center justify-center px-1 py-1 -mt-4 text-xs text-center rounded-full;

  &.fallback {
    @apply text-white bg-gray-500;
  }
  &.orange {
    @apply bg-orange-200 text-oOrange;
  }
  &.green {
    @apply bg-green-200 text-oGreen;
  }
  &.red {
    @apply bg-red-200 text-oRed;
  }
}
</style>
