<template>
  <div class="min-h-screen">
    <ValidationObserver
      v-slot="{ handleSubmit }"
      ref="observer"
      class="vld-parent"
      tag="div"
    >
      <loading :active.sync="isLoading" :is-full-page="false" />

      <form
        @submit.prevent="handleSubmit(submitStepTwo)"
        enctype="multipart/form-data"
        class="px-6"
      >
        <!-- start: parking & reservation -->
        <div class="flex items-center">
          <div class="w-1/2 pr-2">
            <ValidationProvider
              name="Is Parking Required"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Is Parking Required *" />
              <t-rich-select
                placeholder="Select one"
                :options="getParkingRequiredOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.is_parking_required"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Reservation Time"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Fleet Reservation Time *" />
              <t-rich-select
                placeholder="Select one"
                :options="getFleetReservationTimeOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.reservation_time"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <!-- end: parking & reservation -->

        <!-- start: frequency -->
        <div class="flex items-center">
          <div class="w-1/2 pr-2">
            <ValidationProvider
              name="Off Ride Frequency"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label
                :error="errors[0]"
                text="Location Update Frequency (On Ride) *"
              />
              <t-rich-select
                placeholder="Select one"
                :options="getFrequencyOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.on_ride_location_update_frequency"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="On Ride Frequency"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label
                :error="errors[0]"
                text="Location Update Frequency (Off Ride) *"
              />
              <t-rich-select
                placeholder="Select one"
                :options="getFrequencyOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.off_ride_location_update_frequency"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <!-- end: frequency -->

        <!-- start: speed & reservation -->
        <div class="flex items-center">
          <div class="w-1/2 pr-2">
            <ValidationProvider
              name="Max Speed"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Max Speed *" />
              <t-rich-select
                placeholder="Select one"
                :options="getMaxSpeedLimitOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.max_speed_limit"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Fleet visibility"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Fleet Visibility *" />
              <t-rich-select
                placeholder="Select one"
                :options="getVisibilityOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="visibility"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <!-- end: speed & reservation -->

        <!-- start: section -->
        <!-- <div class="flex items-center">
          <div class="w-1/2 pr-2">
            <ValidationProvider
              name="Type"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Fleet Reservation Time *" />
              <t-rich-select
                placeholder="Select one"
                :options="getFleetReservationTimeOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.organization_category"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Billing plan"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Billing Plan *" />
              <t-rich-select
                placeholder="Select one"
                :options="subscriptionPlans"
                valueAttribute="id"
                textAttribute="plan_name"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.billing_plan"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div> -->
        <!-- end: section -->

        <!-- start: private code & refresh time -->
        <div class="flex items-center" v-if="getPrivateCodeVisibilityState">
          <div class="w-1/2 pr-2">
            <text-input
              rules="required"
              label="Private Code"
              placeholder="Private Code"
              v-model="form.private_code"
            />
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Refresh Time (Private Code)"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label
                :error="errors[0]"
                text="Refresh Time (Private Code) *"
              />
              <t-rich-select
                placeholder="Select one"
                :options="getFleetReservationTimeOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.private_code_refresh_time"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <!-- end: private code & refresh time -->

        <label class="flex items-center w-full mt-8 mb-4 cursor-pointer">
          <input
            type="checkbox"
            class="form-checkbox focus:shadow-none text-oBlack focus:border-oDark border-oBlack"
            v-model="form.email_enabled"
          />
          <span class="ml-3 font-semibold select-none text-14px text-oBlack">
            Use Organization Email Configuration
          </span>
        </label>

        <!-- start: section -->
        <div class="flex items-center">
          <!-- <div class="w-1/2 pr-2"> -->
          <div class="w-full">
            <text-input
              rules=""
              label="Email Address"
              placeholder="john_doe@gmail.com"
              v-model="form.email_address"
              :disabled="getUseOrgEmailDisabledState"
            />
          </div>
        </div>
        <!-- end: section -->

        <label class="flex items-center w-full mt-8 mb-4 cursor-pointer">
          <input
            type="checkbox"
            class="form-checkbox focus:shadow-none text-oBlack focus:border-oDark border-oBlack"
            v-model="form.email_is_smtp"
          />
          <span class="ml-3 font-semibold select-none text-14px text-oBlack">
            Email Is SMTP
          </span>
        </label>

        <!-- start: section -->
        <div v-if="!getShowSMTPMailFields" class="flex items-center">
          <div class="w-full">
            <text-input
              rules=""
              label="Email API Key"
              placeholder="xvgsf^$R7865rtxsnb"
              v-model="form.email_api_key"
              :disabled="getUseOrgEmailDisabledState"
            />
          </div>
        </div>

        <div v-if="getShowSMTPMailFields" class="flex items-center">
          <div class="w-1/2 pr-2">
            <text-input
              rules=""
              label="Email Host"
              placeholder="smtp.gmail.com"
              v-model="form.email_host"
              :disabled="getUseOrgEmailDisabledState"
            />
          </div>
          <div class="w-1/2 pl-2">
            <text-input
              rules=""
              label="Email Port"
              placeholder="587"
              v-model="form.email_port"
              :disabled="getUseOrgEmailDisabledState"
            />
          </div>
        </div>
        <!-- end: section -->

        <!-- start: section -->
        <div v-if="getShowSMTPMailFields" class="flex items-center">
          <div class="w-1/2 pl-2">
            <text-input
              rules=""
              label="Email Passoword"
              placeholder="*** *** ***"
              type="password"
              v-model="form.email_password"
              :disabled="getUseOrgEmailDisabledState"
            />
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Email Encryption"
              rules=""
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Email Encryption" />
              <t-rich-select
                placeholder="Select one"
                :options="getEmailEncryptionOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{
                  danger: !!errors[0],
                  readonly: getUseOrgEmailDisabledState,
                }"
                :disabled="getUseOrgEmailDisabledState"
                v-model="form.email_encryption"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <!-- end: section -->

        <button type="submit" ref="stepTwoSubmitButton" class="hidden">
          Save
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { FleetConfig } from '@/config/FleetConfig'
// import { DropdownConfig } from "@/config/DropdownConfig";
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'

export default {
  name: 'AddEditFleetStep2',
  components: {
    InputLabel,
    TextInput,
  },
  data: function() {
    return {
      isLoading: false,
      form: {},
      supportMailConfig: false,
      // selected vehicle types
      vehicleTypes: [],
      visibility: undefined,
      // selected subscription plan
      subscription: undefined,
      subscriptionId: undefined,
      // all subscription plans detail
      subscriptionPlans: undefined,
      // getBillingPlanOptions: undefined
    }
  },
  computed: {
    ...mapGetters('fleet', ['isEditing', 'currentEditingFleetId']),
    ...mapState({
      // retrieve organization detail from step 1
      step1: (state) => state.fleet.step1,
      // retrieve subscription detail from step 2
      step2: (state) => state.fleet.step2,
    }),
    getParkingRequiredOptions: function() {
      return [
        {
          value: 'YES',
          text: 'Yes',
        },
        {
          value: 'NO',
          text: 'No',
        },
      ]
    },
    getVisibilityOptions: function() {
      return [
        {
          value: 'PU',
          text: 'Public',
        },
        {
          value: 'PR',
          text: 'Private',
        },
        // {
        //   value: "KP",
        //   text: "Keep"
        // }
        //     ("PU", "Public"),
        // ("PR", "Private"),
        // ("KP", "Keep")
      ]
    },
    getPrivateCodeVisibilityState() {
      return this.visibility === 'PR' ?? false
    },
    getShowSMTPMailFields() {
      return this.form.email_is_smtp === true
    },
    getFleetReservationTimeOptions: function() {
      return [
        {
          value: 5,
          text: '5 Min',
        },
        {
          value: 10,
          text: '10 Min',
        },
        {
          value: 15,
          text: '15 Min',
        },
        {
          value: 20,
          text: '20 Min',
        },
        {
          value: 25,
          text: '25 Min',
        },
        {
          value: 30,
          text: '30 Min',
        },
      ]
    },
    getMaxSpeedLimitOptions: function() {
      return [
        {
          value: 0,
          text: 'Low',
        },
        {
          value: 1,
          text: 'Medium',
        },
        {
          value: 2,
          text: 'High',
        },
      ]
    },
    getFrequencyOptions: function() {
      return [
        {
          value: 30,
          text: '30s',
        },
        {
          value: 60,
          text: '1m',
        },
        {
          value: 90,
          text: '1m 30s',
        },
        {
          value: 120,
          text: '2m',
        },
        {
          value: 150,
          text: '2m 30s',
        },
        {
          value: 180,
          text: '3m',
        },
        {
          value: 210,
          text: '3m 30s',
        },
        {
          value: 240,
          text: '4m',
        },
      ]
    },
    getEmailEncryptionOptions: function() {
      return [
        {
          value: 0,
          text: 'TLS',
        },
        {
          value: 1,
          text: 'SSL',
        },
        {
          value: 2,
          text: 'None',
        },
      ]
    },
    getUseOrgEmail: function() {
      return this.form.email_enabled
    },
    getUseOrgEmailDisabledState: function() {
      return !!this.getUseOrgEmail
    },
  },
  async created() {
    console.log('data = ', this.step2, this.isEditing)
    if (this.step2) {
      // console.log(this.step2.subscriptionId);
      // this.form = this.step2;
      if (this.step2.is_parking_required == false) {
        this.step2.is_parking_required = 'YES'
      } else if (this.step2.is_parking_required == true) {
        this.step2.is_parking_required = 'NO'
      }

      if (this.step2.visibility == false) {
        this.step2.visibility = 'YES'
      } else if (this.step2.visibility == true) {
        this.step2.visibility = 'NO'
      }

      if (this.step2.max_speed_limit == 'LOW') {
        this.step2.max_speed_limit = 0
      } else if (this.step2.max_speed_limit == 'MEDIUM') {
        this.step2.max_speed_limit = 1
      } else if (this.step2.max_speed_limit == 'HIGH') {
        this.step2.max_speed_limit = 2
      }
      this.visibility = this.step2.visibility
      this.form = this.step2
    }
  },
  watch: {
    getUseOrgEmail: function(updatedState) {
      if (updatedState === true) {
        // todo: fetch from server
        // alert("okkk");
        this.form.email_host = 'smtp.gmail.com'
        this.form.email_port = '587'
        this.form.email_address = 'the_scoot@gmail.com'
        this.form.email_password = 'secret'
        this.form.email_encryption = 0
      } else {
        this.form.email_host = ''
        this.form.email_port = ''
        this.form.email_address = ''
        this.form.email_password = ''
        this.form.email_encryption = ''
      }
    },
    // subscriptionId: function(updatedSubscriptionId) {
    //   this.subscription = this.subscriptionPlans.find(subscription => {
    //     return subscription.id === updatedSubscriptionId
    //       ? subscription
    //       : undefined;
    //   });
    // }
  },
  methods: {
    reloadData() {
      console.log('update = ', this.step2)
      if (this.step2.is_parking_required == false) {
        this.step2.is_parking_required = 'YES'
      } else if (this.step2.is_parking_required == true) {
        this.step2.is_parking_required = 'NO'
      }

      if (this.step2.max_speed_limit == 'LOW') {
        this.step2.max_speed_limit = 0
      } else if (this.step2.max_speed_limit == 'MEDIUM') {
        this.step2.max_speed_limit = 1
      } else if (this.step2.max_speed_limit == 'HIGH') {
        this.step2.max_speed_limit = 2
      }
      this.visibility = this.step2.visibility
      this.form = this.step2
    },
    submit: async function(btn_type, index, isNext, isPrevious) {
      if (btn_type != 'normal' && this.isEditing) {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepTwoSubmitButton.click()
      }
    },
    submitStepTwo: async function() {
      this.isLoading = true
      // console.log(this.form);
      console.log('fs1', this.step1)

      let method = 'PATCH'
      let url = FleetConfig.api.update(this.currentEditingFleetId)
      console.log('id = ', this.currentEditingFleetId)

      this.form.is_parking_required =
        this.form.is_parking_required === 'YES' ? true : false

      this.form.visibility = this.visibility
      if (this.form.email_encryption == 0) {
        this.form.email_encryption = 'TLS'
      } else if (this.form.email_encryption == 1) {
        this.form.email_encryption = 'SSL'
      } else if (this.form.email_encryption == 2) {
        this.form.email_encryption = 'None'
      }

      if (this.form.max_speed_limit == 0) {
        this.form.max_speed_limit = 'LOW'
      } else if (this.form.max_speed_limit == 1) {
        this.form.max_speed_limit = 'MEDIUM'
      } else if (this.form.max_speed_limit == 2) {
        this.form.max_speed_limit = 'HIGH'
      }
      console.log('form = ', this.form)

      let data = new xMan({
        // ...this.step1,
        ...this.form,
        // organization: ""
      }).toFormData()
      let message = this.isEditing
        ? `Fleet Configuration updated successfully`
        : `Fleet Configuration added successfully`

      // todo: remove logs
      console.log('step2 started submitting')
      // console.log({ step2: data });
      // console.log({ step2: url });

      try {
        let response = await this.$http({ method, url, data })
        console.log(typeof response)
        console.log({ res: response.data })
        this.isLoading = false
        // this.form = {};
        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )

        // Resolved
        EventBus.$emit(FleetConfig.events.succeededStep2, {
          res: response.data,
        })
        return true
      } catch (e) {
        // todo: remove logs
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        // console.log({ sdhf: typeof error });
        // console.log({ e1: Object.keys(error) });
        console.log({ e2: e })
        // for (let i = 0; i < e.response.data.length; i++) {
        //   this.errors.push(e.response.data[i]);
        // }
        // Rejected
        // throw new Error(error.status);
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
